exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".market-common-styles_MarketTemplateTitle {\n  color: #D7DDE0;\n  display: flex;\n  flex-direction: column;\n  font-weight: bold;\n}\n.market-common-styles_MarketTemplateTitle > a > div,\n.market-common-styles_MarketTemplateTitle > div {\n  display: flex;\n}\n.market-common-styles_TEXT {\n  white-space: nowrap;\n  border-bottom: 1px solid #F9FFD7;\n}\n.market-common-styles_CustomMarket {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.625rem;\n  font-weight: 700;\n  background: rgba(255, 39, 39, 0.2);\n  border-radius: 0.1875rem;\n  color: #FF2727;\n  margin: 0 0 0.5rem 0;\n  text-transform: uppercase;\n  width: -webkit-max-content;\n  width: max-content;\n}\n.market-common-styles_CustomMarket.market-common-styles_TopMargin {\n  margin-top: 0.5rem;\n}\n.market-common-styles_CustomMarket > svg {\n  width: 0.5rem;\n  height: 0.5rem;\n  margin-right: 0.25rem;\n}\n.market-common-styles_CustomMarket > svg > circle {\n  fill: #FF2727;\n}\n", ""]);

// exports
exports.locals = {
	"MarketTemplateTitle": "market-common-styles_MarketTemplateTitle",
	"marketTemplateTitle": "market-common-styles_MarketTemplateTitle",
	"TEXT": "market-common-styles_TEXT",
	"text": "market-common-styles_TEXT",
	"CustomMarket": "market-common-styles_CustomMarket",
	"customMarket": "market-common-styles_CustomMarket",
	"TopMargin": "market-common-styles_TopMargin",
	"topMargin": "market-common-styles_TopMargin"
};