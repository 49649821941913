exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".market-outcomes-list-styles_Heading {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 700;\n  align-items: center;\n  background-color: #0E0E0F;\n  border-bottom: 1px solid #0E0E0F;\n  display: flex;\n  height: 2rem;\n  justify-content: space-between;\n  min-height: 2rem;\n  padding: 0.5rem 0.75rem 0.5rem 1rem;\n  width: 100%;\n  text-transform: uppercase;\n}\n.market-outcomes-list-styles_Heading > span {\n  display: flex;\n}\n@media all and (max-width: 767px) {\n  .market-outcomes-list-styles_OutcomesList {\n    background: #1B1A1E;\n    border-radius: 1px;\n    color: #D7DDE0;\n    border: 1px solid #0E0E0F;\n    border-bottom: 0;\n  }\n  .market-outcomes-list-styles_Heading {\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    font-family: 'Inter', sans-serif;\n    font-feature-settings: \"tnum\";\n    font-size: 0.75rem;\n    font-weight: 700;\n    text-transform: uppercase;\n    padding: 0.75rem 1rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Heading": "market-outcomes-list-styles_Heading",
	"heading": "market-outcomes-list-styles_Heading",
	"OutcomesList": "market-outcomes-list-styles_OutcomesList",
	"outcomesList": "market-outcomes-list-styles_OutcomesList"
};