exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".visibility-styles_Visibility > div:not(:first-of-type) {\n  margin-top: 1.5rem;\n}\n.visibility-styles_Visibility > div:nth-of-type(2) > span {\n  color: #FF7D5E;\n}\n@media all and (max-width: 767px) {\n  .visibility-styles_Visibility > div:not(:first-of-type) {\n    margin-top: 0.875rem;\n  }\n}\n.visibility-styles_Positive {\n  color: #00F1C4;\n}\n.visibility-styles_Passing > div:nth-of-type(2) > span {\n  color: #00F1C4;\n}\n", ""]);

// exports
exports.locals = {
	"Visibility": "visibility-styles_Visibility",
	"visibility": "visibility-styles_Visibility",
	"Positive": "visibility-styles_Positive",
	"positive": "visibility-styles_Positive",
	"Passing": "visibility-styles_Passing",
	"passing": "visibility-styles_Passing"
};