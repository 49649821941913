exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".portfolio-view-styles_PortfolioView {\n  display: flex;\n  flex: 1;\n  height: 100%;\n  max-width: 2000px;\n  margin: 0 auto;\n}\n.portfolio-view-styles_PortfolioView > section {\n  display: flex;\n  flex: 1;\n  margin-bottom: 0;\n}\n.portfolio-view-styles_PortfolioView > section > div {\n  flex: 1;\n  flex-direction: column;\n  margin: 0.5rem;\n  height: 100%;\n}\n.portfolio-view-styles_PortfolioView > section > div > div {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 0.5rem;\n  max-height: 50%;\n}\n.portfolio-view-styles_PortfolioView > section > div:last-of-type {\n  margin-left: 0;\n}\n@media all and (min-width: 768px) and (max-width: 1150px) {\n  .portfolio-view-styles_PortfolioView > section {\n    flex-direction: column;\n  }\n  .portfolio-view-styles_PortfolioView > section > div {\n    margin: 1rem 1.5rem;\n  }\n  .portfolio-view-styles_PortfolioView > section > div:last-of-type {\n    margin-left: 1.5rem;\n  }\n  .portfolio-view-styles_PortfolioView > section > div > div:first-of-type {\n    margin-bottom: 1rem;\n  }\n  .portfolio-view-styles_PortfolioView > section > div > div:last-of-type {\n    margin-bottom: 0;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"PortfolioView": "portfolio-view-styles_PortfolioView",
	"portfolioView": "portfolio-view-styles_PortfolioView"
};