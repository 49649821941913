exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".market-type-filter-styles_MarketTypeLabels {\n  display: flex;\n}\n.market-type-filter-styles_MarketTypeLabels > div {\n  margin: 0 0 0 1.75rem;\n}\n.market-type-filter-styles_MarketTypeLabels > div:first-of-type {\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"MarketTypeLabels": "market-type-filter-styles_MarketTypeLabels",
	"marketTypeLabels": "market-type-filter-styles_MarketTypeLabels"
};