exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".quad-styles_Column {\n  display: flex;\n  flex-direction: column;\n}\n.quad-styles_Column > span:first-of-type {\n  margin-bottom: 1px;\n}\n.quad-styles_MultiColumn {\n  display: flex;\n}\n.quad-styles_MultiColumn > span {\n  margin-right: 1.5rem;\n}\n.quad-styles_InfoParent {\n  background-color: #222126;\n  display: flex;\n  flex: 1;\n}\n.quad-styles_InfoParent > div {\n  align-items: flex-end;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-end;\n  padding: 0.5rem 1.625rem 0.5rem 0;\n  border-top: 1px solid #0E0E0F;\n  margin: 0 1rem;\n}\n@media all and (max-width: 767px) {\n  .quad-styles_InfoParent > div {\n    align-items: unset;\n    border-top: 0;\n    padding: 0.5rem 0.75rem;\n    margin: 0;\n  }\n}\n.quad-styles_InfoParent > div > div {\n  max-width: 300px;\n}\n.quad-styles_InfoParent > div > span {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  color: #9599A5;\n  width: 100%;\n}\n.quad-styles_InfoParent.quad-styles_Failure > div {\n  align-items: flex-start;\n}\n.quad-styles_InfoParent.quad-styles_Failure > div > span {\n  margin-bottom: 1.5rem;\n}\n.quad-styles_InfoParent.quad-styles_Failure > div > div {\n  display: flex;\n  flex: 1;\n}\n.quad-styles_InfoParent.quad-styles_Failure > div > div button:first-of-type {\n  margin-right: 1rem;\n}\n@media all and (max-width: 767px) {\n  .quad-styles_InfoParent > div > div {\n    max-width: unset;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Column": "quad-styles_Column",
	"column": "quad-styles_Column",
	"MultiColumn": "quad-styles_MultiColumn",
	"multiColumn": "quad-styles_MultiColumn",
	"InfoParent": "quad-styles_InfoParent",
	"infoParent": "quad-styles_InfoParent",
	"Failure": "quad-styles_Failure",
	"failure": "quad-styles_Failure"
};