exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".search-sort-styles_SearchSort {\n  align-items: center;\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n}\n.search-sort-styles_SearchSort.search-sort-styles_HideDropdown > div:first-of-type {\n  display: none;\n}\n@media all and (max-width: 767px) {\n  .search-sort-styles_SearchSort {\n    justify-content: space-between;\n  }\n}\n.search-sort-styles_Dropdown {\n  flex: 1;\n  margin-top: 0.85rem;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"SearchSort": "search-sort-styles_SearchSort",
	"searchSort": "search-sort-styles_SearchSort",
	"HideDropdown": "search-sort-styles_HideDropdown",
	"hideDropdown": "search-sort-styles_HideDropdown",
	"Dropdown": "search-sort-styles_Dropdown",
	"dropdown": "search-sort-styles_Dropdown"
};