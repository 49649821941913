exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".disputing-styles_Disputing {\n  display: grid;\n  grid-gap: 0 0.5rem;\n  max-width: 1657px;\n  padding: 1rem 0.5rem;\n  grid-template-columns: minmax(767px, 1fr) minmax(340px, 440px);\n  margin: auto;\n}\n.disputing-styles_Disputing > div {\n  grid-column: 2;\n  margin-bottom: 0.5rem;\n}\n.disputing-styles_Disputing > div > button:first-child {\n  height: 2.5rem;\n  margin-bottom: 0.5rem;\n}\n.disputing-styles_Disputing > div:first-of-type {\n  grid-column: 1;\n  grid-row: 1 / span 4;\n}\n@media all and (min-width: 768px) and (max-width: 1150px) {\n  .disputing-styles_Disputing {\n    grid-template-columns: 2fr 1fr;\n  }\n}\n@media all and (max-width: 767px) {\n  .disputing-styles_Disputing {\n    grid-template-columns: unset;\n    padding: 0;\n  }\n  .disputing-styles_Disputing > div > div:last-of-type {\n    padding: 0 0.5rem;\n  }\n  .disputing-styles_Disputing > div > div:last-of-type > div {\n    margin-bottom: 0.5rem;\n  }\n  .disputing-styles_Disputing > div > div:last-of-type > button {\n    margin: 1rem 0;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Disputing": "disputing-styles_Disputing",
	"disputing": "disputing-styles_Disputing"
};