import { updateSdk } from 'modules/auth/actions/update-sdk';
import { toChecksumAddress } from 'ethereumjs-util';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { PersonalSigningWeb3Provider } from 'utils/personal-signing-web3-provider';
import { ACCOUNT_TYPES } from 'modules/common/constants';
import { windowRef } from 'utils/window-ref';
import { LoginAccount } from 'modules/types';
import { AppState } from 'appStore';
import { getNetwork } from 'utils/get-network-name';
import { Web3Provider, JsonRpcProvider } from 'ethers/providers';
import { ethers } from 'ethers';
import * as Web3 from 'web3';

export const loginWithTorus = (data: any) => async (
  dispatch: ThunkDispatch<void, any, Action>,
  getState: () => AppState,
) => {
  console.log("google login data", data)
  const networkId: string = getState().env['networkId'];
  const torusNetwork = getNetwork(networkId);
  let accountObject: Partial<LoginAccount> = {};
  // getOwnWeb3Provider()
  //   .getBlockNumber()
  //   .then((blockNumber) => console.log("Block Number:", blockNumber))
  //   .catch((error) => console.error("Connection Error:", error));
  // Use import instead of import for wallet SDK packages
  // to conditionally load web3 into the DOM
  // const { default: Torus } = await import( /*webpackChunkName: 'torus'*/ '@toruslabs/torus-embed');
  //const torus = new Torus({});

  if (torusNetwork) {
    if (torusNetwork === 'localhost') {
      throw new Error('localhost currently not working for torus')
    }

    try {
      let accountKeys = [
        {
          "privateKey": "48c5da6dff330a9829d843ea90c2629e8134635a294c7e62ad4466eb2ae03712",
          "address": "0xbd355A7e5a7ADb23b51F54027E624BfE0e238DF6",
          "email": "sangram@chainflux.com"
        },
        {
          "privateKey": "8a4edfe30b4cdc9064b2e72d28bce9a650c24c6193de5058c96c296bc22d25d1",
          "address": "0xE4EC477Bc4Abd2B18225Bb8CBa14BF57867f082B",
          "email": "singh.sangram56@gmail.com"
        }
      ]
      let accountKey = accountKeys.filter((key) => key.email === data.email);
      if (accountKey.length === 0) {
        alert("wallet not found");
        return;
      }
      const provider = new PersonalSigningWeb3Provider(getOwnWeb3Provider().provider).provider;
      const wallet = new ethers.Wallet(accountKey[0].privateKey);
      // console.log("wallet", wallet)
      const signer = wallet.connect(provider);
      // const provider = getOwnWeb3Provider(); 
      //let blockNumber22 = await provider.getBlockNumber();
      //console.log("block22 ===>", blockNumber22)

      const isWeb3 = true;
      // windowRef.torus = torus;
      const account = toChecksumAddress(accountKey[0].address);
      accountObject = {
        address: account,
        mixedCaseAddress: account,
        meta: {
          address: account,
          email: null,
          profileImage: null,
          openWallet: null,
          provider,
          signer: signer,
          accountType: ACCOUNT_TYPES.TORUS,
          isWeb3,
        },
      };
      console.log('accountObject', accountObject)
      // Torus just hides the button but its still clickable.
      // Setting styles to display none as to not trigger an error during Torus cleanup
      // if (document.querySelector('#torusWidget')) {
      //   document
      //     .querySelector('#torusWidget')
      //     .setAttribute('style', 'display:none');
      // }

    } catch (error) {
      console.log("error", error)
      // if (document.querySelector('#torusWidget')) {
      //   document.querySelector('#torusWidget').remove();
      // }
      // // On error, we need to cleanup the second instance of the torus iframes
      // const torusIframe = document.querySelectorAll('#torusIframe');
      // if (torusIframe.length > 0 && torusIframe[1]) {
      //   torusIframe[1].remove();
      // }

      // throw error;
    }

    try {
      // const userInfo = await torus.getUserInfo(
      //   'Augur would like to use this information to improve your user experience.'
      // );
      accountObject.meta.email = data.email;
      accountObject.meta.profileImage = data.picture ? data.picture : null;
      dispatch(updateSdk(accountObject, undefined));
    } catch (error) {
      // User denied request
      dispatch(updateSdk(accountObject, undefined));
    }
  } else {
    throw Error('Network currently not supported with Torus');
  }
};
export const getOwnWeb3Provider = () => {
  const customHttpProvider = new ethers.providers.JsonRpcProvider('https://bet-node.resonate.lol/ext/bc/myblockchain/rpc');
  console.log("Created Web3Provider with custom HTTP provider:", customHttpProvider);
  return new Web3Provider(customHttpProvider);
};