exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".markdown-renderer-styles_MarkdownRenderer {\n  white-space: pre-wrap;\n}\n.markdown-renderer-styles_MarkdownRenderer.markdown-renderer-styles_NoPrewrap {\n  white-space: unset;\n}\n.markdown-renderer-styles_MarkdownRenderer p {\n  line-height: 140%;\n  margin: 0;\n  text-overflow: ellipsis;\n}\n.markdown-renderer-styles_MarkdownRenderer p:nth-of-type(2) {\n  margin-top: 0.25rem;\n}\n.markdown-renderer-styles_MarkdownRenderer ul {\n  display: block;\n  list-style-type: disc;\n  margin-block-end: 1rem;\n  margin-block-start: 1rem;\n  margin-inline-end: 0;\n  margin-inline-start: 0;\n  padding-inline-start: 2.5rem;\n}\n.markdown-renderer-styles_MarkdownRenderer ol {\n  margin-block-end: 1rem;\n  margin-block-start: 1rem;\n}\n.markdown-renderer-styles_MarkdownRenderer h2,\n.markdown-renderer-styles_MarkdownRenderer h3,\n.markdown-renderer-styles_MarkdownRenderer h4,\n.markdown-renderer-styles_MarkdownRenderer h5,\n.markdown-renderer-styles_MarkdownRenderer h6 {\n  margin-block-end: 1rem;\n  margin-block-start: 1rem;\n  margin-inline-end: 0;\n  margin-inline-start: 0;\n  padding: 0 !important;\n}\n.markdown-renderer-styles_MarkdownRenderer del {\n  text-decoration: none;\n}\n", ""]);

// exports
exports.locals = {
	"MarkdownRenderer": "markdown-renderer-styles_MarkdownRenderer",
	"markdownRenderer": "markdown-renderer-styles_MarkdownRenderer",
	"NoPrewrap": "markdown-renderer-styles_NoPrewrap",
	"noPrewrap": "markdown-renderer-styles_NoPrewrap"
};