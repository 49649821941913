exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".saved-drafts-styles_SavedDrafts {\n  max-height: 250px;\n}\n.saved-drafts-styles_DraftRow {\n  border-bottom: 1px solid #0E0E0F;\n  display: flex;\n  flex-grow: 1;\n  padding: 1.125rem 2rem;\n  background-color: #1B1A1E;\n  flex-direction: row;\n  cursor: pointer;\n  align-items: center;\n}\n.saved-drafts-styles_DraftRow > button:first-of-type {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-right: 0.625rem;\n}\n.saved-drafts-styles_DraftRow > button:first-of-type > span:first-of-type {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  font-weight: 500;\n  color: #D7DDE0;\n  text-align: left;\n  word-break: break-word;\n}\n.saved-drafts-styles_DraftRow > button:first-of-type > span:last-of-type {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 700;\n  color: #9599A5;\n  margin-top: 0.25rem;\n  text-align: left;\n}\n.saved-drafts-styles_DraftRow > button:last-of-type {\n  height: -webkit-min-content;\n  height: min-content;\n}\n@media all and (max-width: 767px) {\n  .saved-drafts-styles_DraftRow {\n    padding: 1rem 0;\n    margin: 0 1rem;\n    border-bottom: 2px solid #0E0E0F;\n  }\n  .saved-drafts-styles_DraftRow > button:first-of-type > span:last-of-type {\n    font-family: 'Inter', sans-serif;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    font-size: 0.625rem;\n    font-weight: 500;\n    margin-top: 0.375rem;\n  }\n}\n.saved-drafts-styles_DraftRow:last-of-type {\n  border-bottom: 0;\n}\n", ""]);

// exports
exports.locals = {
	"SavedDrafts": "saved-drafts-styles_SavedDrafts",
	"savedDrafts": "saved-drafts-styles_SavedDrafts",
	"DraftRow": "saved-drafts-styles_DraftRow",
	"draftRow": "saved-drafts-styles_DraftRow"
};