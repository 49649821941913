exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".terms-and-conditions-styles_TermsAndConditions {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  margin-right: 6.875rem;\n}\n.terms-and-conditions-styles_TermsAndConditions > a {\n  padding: 0 1rem;\n  text-decoration: underline;\n}\n.terms-and-conditions-styles_TermsAndConditions > a:last-of-type {\n  border-left: 1px solid #9599A5;\n}\n", ""]);

// exports
exports.locals = {
	"TermsAndConditions": "terms-and-conditions-styles_TermsAndConditions",
	"termsAndConditions": "terms-and-conditions-styles_TermsAndConditions"
};