exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".empty-display-styles_EmptyDisplay {\n  align-items: center;\n  color: #4E4C57;\n  display: flex;\n  flex: 1;\n  font-size: 0.75rem;\n  height: 100%;\n  justify-content: center;\n  margin: 2rem 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"EmptyDisplay": "empty-display-styles_EmptyDisplay",
	"emptyDisplay": "empty-display-styles_EmptyDisplay"
};