exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".market-positions-table-styles_PositionsTable {\n  background: #1B1A1E;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n", ""]);

// exports
exports.locals = {
	"PositionsTable": "market-positions-table-styles_PositionsTable",
	"positionsTable": "market-positions-table-styles_PositionsTable"
};