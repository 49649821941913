exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".stats-styles_Stats {\n  border-radius: 0.1875rem;\n  background: #161518;\n  padding: 1rem;\n  display: grid;\n  grid-gap: 0.75rem 1rem;\n  grid-template-columns: repeat(2, 1fr);\n}\n.stats-styles_Stats > ul:first-of-type {\n  grid-column: 1 / span 2;\n  margin: 0 auto;\n}\n@media all and (max-width: 768px) {\n  .stats-styles_Stats > ul:first-of-type {\n    grid-column: 1;\n    margin: 0.375rem auto;\n  }\n}\n.stats-styles_Stats > div[class*=\"LinearPropertyLabel\"] {\n  align-items: baseline;\n  margin: 0;\n}\n.stats-styles_Stats > div[class*=\"LinearPropertyLabel\"] > span:first-of-type {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.625rem;\n  font-weight: 500;\n  color: #9599A5;\n}\n.stats-styles_Stats > div[class*=\"LinearPropertyLabel\"] > span {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 500;\n  color: #D7DDE0;\n}\n@media all and (max-width: 768px) {\n  .stats-styles_Stats {\n    grid-template-columns: 1fr;\n    grid-gap: 0.625rem 0;\n    padding: 0.75rem 1.75rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Stats": "stats-styles_Stats",
	"stats": "stats-styles_Stats"
};