exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".template-picker-styles_TemplatePicker > div:first-of-type {\n  margin-bottom: 1.5rem;\n}\n.template-picker-styles_TemplatePicker > div:nth-of-type(2) > button {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.6875rem;\n  font-weight: 700;\n  color: #9599A5;\n  padding-bottom: 0.625rem;\n}\n.template-picker-styles_TemplatePicker > div:nth-of-type(2) > button.template-picker-styles_Selected {\n  color: #D7DDE0;\n  border-bottom: 1px solid #7DFFA8;\n}\n.template-picker-styles_TemplatePicker > div:nth-of-type(2) > button:not(:first-of-type) {\n  margin-left: 2.5rem;\n}\n.template-picker-styles_TemplatePicker > section:last-of-type {\n  margin-top: 1.5rem;\n}\n.template-picker-styles_TemplatePicker > section:last-of-type > div {\n  margin-bottom: 1rem;\n}\n.template-picker-styles_TemplatePicker > div:last-of-type > section:last-of-type > ul {\n  margin: 1.5rem 0;\n}\n@media all and (max-width: 767px) {\n  .template-picker-styles_TemplatePicker > section:last-of-type {\n    margin-top: 1rem;\n  }\n  .template-picker-styles_TemplatePicker > section:last-of-type > div {\n    margin-bottom: 0;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"TemplatePicker": "template-picker-styles_TemplatePicker",
	"templatePicker": "template-picker-styles_TemplatePicker",
	"Selected": "template-picker-styles_Selected",
	"selected": "template-picker-styles_Selected"
};