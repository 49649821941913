exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".quad-box-styles_Quad {\n  background: #1B1A1E;\n  border: 1px solid #0E0E0F;\n  border-radius: 0.1875rem;\n  color: #D7DDE0;\n  flex: 1;\n  min-height: 50%;\n}\n@media all and (max-width: 767px) {\n  .quad-box-styles_Quad {\n    background: unset;\n    border: 0;\n    padding-bottom: 0;\n  }\n}\n.quad-box-styles_Quad > div:last-of-type {\n  background: #1B1A1E;\n  flex: 1;\n  overflow: auto;\n  overflow-y: overlay;\n  display: flex;\n  flex-direction: column;\n}\n@media all and (max-width: 767px) {\n  .quad-box-styles_Quad > div:last-of-type {\n    background: unset;\n    min-height: calc(100vh - 6rem);\n    max-height: calc(100vh - 6rem);\n    overflow-y: visible;\n    padding: 0 0.375rem 0.25rem;\n  }\n}\n.quad-box-styles_Quad.quad-box-styles_NoBorders {\n  padding-bottom: 0.5rem;\n}\n.quad-box-styles_Quad.quad-box-styles_NoBorders > div:last-of-type {\n  border-bottom: 0;\n}\n.quad-box-styles_Quad.quad-box-styles_HideToggle {\n  max-height: 43px;\n  min-height: unset;\n  overflow-y: hidden;\n  flex: 0;\n}\n.quad-box-styles_Quad.quad-box-styles_Extend {\n  min-height: calc(100% - 43px);\n}\n.quad-box-styles_ShowOnMobile {\n  display: none;\n}\n@media all and (max-width: 767px) {\n  .quad-box-styles_NormalOnMobile {\n    background: #1B1A1E;\n    border: 1px solid #0E0E0F;\n  }\n  .quad-box-styles_ShowOnMobile {\n    display: block;\n  }\n  .quad-box-styles_HideOnMobile {\n    display: none;\n  }\n  .quad-box-styles_Hide {\n    display: none;\n  }\n}\n@media all and (max-width: 767px) {\n  .quad-box-styles_MarketBox {\n    border: 1px solid #0E0E0F;\n    margin-top: 0.5rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Quad": "quad-box-styles_Quad",
	"quad": "quad-box-styles_Quad",
	"NoBorders": "quad-box-styles_NoBorders",
	"noBorders": "quad-box-styles_NoBorders",
	"HideToggle": "quad-box-styles_HideToggle",
	"hideToggle": "quad-box-styles_HideToggle",
	"Extend": "quad-box-styles_Extend",
	"extend": "quad-box-styles_Extend",
	"ShowOnMobile": "quad-box-styles_ShowOnMobile",
	"showOnMobile": "quad-box-styles_ShowOnMobile",
	"NormalOnMobile": "quad-box-styles_NormalOnMobile",
	"normalOnMobile": "quad-box-styles_NormalOnMobile",
	"HideOnMobile": "quad-box-styles_HideOnMobile",
	"hideOnMobile": "quad-box-styles_HideOnMobile",
	"Hide": "quad-box-styles_Hide",
	"hide": "quad-box-styles_Hide",
	"MarketBox": "quad-box-styles_MarketBox",
	"marketBox": "quad-box-styles_MarketBox"
};