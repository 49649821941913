import React from 'react';
import {
  PrimarySignInButton,
  SecondarySignInButton,
} from 'modules/common/buttons';
import { Close } from 'modules/common/icons';

import Styles from 'modules/modal/modal.styles.less';

import { GoogleLogin } from '@react-oauth/google';
import { ACCOUNT_TYPES } from 'modules/common/constants';
import Styles from 'modules/common/buttons.styles.less';
import * as classNames from 'classnames';

interface ConnectMethod {
  hidden: boolean;
  disabled: boolean;
  action: Function;
  type: string;
  text: string;
  subText: string;
  icon: React.ReactFragment;
  primary: boolean;
}

interface LoginProps {
  closeModal: Function;
  signupModal: Function;
  loginModal: Function;
  hardwareWalletModal: Function;
  isLogin: boolean;
  connectMethods: ConnectMethod[];
}

export const SignIn = (props: LoginProps) => {
  const {
    closeModal,
    signupModal,
    loginModal,
    hardwareWalletModal,
    isLogin = true,
    connectMethods,

  } = props;

  const LOGIN_OR_SIGNUP = isLogin ? 'Login' : 'Signup';
  const parimaryButtonsToShow = connectMethods
    .filter(method => !method.hidden)
    .filter(method => method.primary)
    .map((method, idx) => (
      <PrimarySignInButton
        key={idx}
        action={method.action}
        text={method.text}
        subText={method.subText}
        icon={method.icon}
      />
    ));

  const secondaryButtonsToShow = connectMethods
    .filter(method => !method.hidden)
    .filter(method => !method.primary)
    .map((method, idx) => (
      <SecondarySignInButton
        key={idx}
        action={method.action}
        text={method.text}
        subText={method.subText}
        disabled={true}
        icon={method.icon}
      />
    ));
  
  const decodeJwtResponse=(token) =>{
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
  const responseMessage = (response) => {
    console.log(decodeJwtResponse(response.credential));
    let profileData=decodeJwtResponse(response.credential)
    connectMethods.filter((key)=>key.type===ACCOUNT_TYPES.TORUS)[0].action(profileData)
    };
    const errorMessage = (error) => {
        console.log(error);
    };
  
  return (
    <div className={Styles.SignIn}>
      <div onClick={() => closeModal()}>{Close}</div>
      <header>
        <div>{LOGIN_OR_SIGNUP}</div>
        {isLogin ? (
          <div>
            New to Augur? <span onClick={() => signupModal()}>Signup</span>
          </div>
        ) : (
          <div>
            Already an Augur user?{' '}
            <span onClick={() => loginModal()}>Login</span>
          </div>
        )}
      </header>

      {parimaryButtonsToShow}

      <div>OR</div>
      <div style={{ width: "100%" }}>
         <GoogleLogin onSuccess={responseMessage} onError={errorMessage}  />
      </div> 
      <footer>
        <div>
          Want to use a hardware wallet?{' '}
          <span onClick={() => hardwareWalletModal(isLogin)}>
            Learn more
          </span>
        </div>
      </footer>
    </div>
  );
};

