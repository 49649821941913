exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".universe-card-styles_UniverseCard {\n  background-color: #1B1A1E;\n  border: 0.0625rem solid #0E0E0F;\n  padding: 1.1875rem 1.5rem;\n  margin-bottom: 0.75rem;\n  max-width: 21.875rem;\n}\n.universe-card-styles_UniverseCard > span {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.625rem;\n  border: 1px solid transparent;\n  clear: both;\n  color: #00F1C4;\n  display: inline-block;\n  line-height: 0.6875rem;\n  padding: 0 1px;\n  position: relative;\n  border-radius: 0.1875rem;\n  text-transform: uppercase;\n  background: rgba(125, 255, 168, 0.15);\n  margin-bottom: 1.1875rem;\n}\n.universe-card-styles_UniverseCard > div > h1 {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.5625rem;\n  font-weight: 700;\n  color: #9599A5;\n  margin-bottom: 0.25rem;\n  text-transform: uppercase;\n}\n.universe-card-styles_UniverseCard > div:first-of-type {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 1.125rem;\n  font-weight: 700;\n  color: #D7DDE0;\n  margin-bottom: 1.1875rem;\n}\n.universe-card-styles_UniverseCard > div:nth-of-type(2) {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.625rem;\n  font-weight: 700;\n  color: #D7DDE0;\n  margin-bottom: 1.1875rem;\n}\n.universe-card-styles_UniverseCard > button {\n  margin-top: 1.1875rem;\n}\n", ""]);

// exports
exports.locals = {
	"UniverseCard": "universe-card-styles_UniverseCard",
	"universeCard": "universe-card-styles_UniverseCard"
};