exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".filter-dropdowns-styles_FilterDropdowns {\n  align-items: center;\n  display: flex;\n  flex-flow: row nowrap;\n}\n.filter-dropdowns-styles_FilterDropdowns > div:first-of-type {\n  min-width: 250px;\n}\n.filter-dropdowns-styles_FilterDropdowns > div:first-of-type > button > span::before {\n  content: 'Sort By:';\n  padding-right: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"FilterDropdowns": "filter-dropdowns-styles_FilterDropdowns",
	"filterDropdowns": "filter-dropdowns-styles_FilterDropdowns"
};