exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media all and (min-width: 768px) and (max-width: 1150px) {\n}.market-header-bar-styles_HeaderBar {\n  display: grid;\n  grid-template-columns: auto 1fr 1fr;\n  margin: 0.625rem 1rem;\n  align-items: center;\n}\n@media all and (max-width: 767px) {\n  .market-header-bar-styles_HeaderBar {\n    margin: 0 0.875rem 1rem;\n    padding-top: 1.125rem;\n  }\n}\n.market-header-bar-styles_HeaderBar > label {\n  margin-right: 0.75rem;\n  margin-bottom: 0;\n  display: flex;\n}\n.market-header-bar-styles_HeaderBar > span:first-of-type {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.5625rem;\n  font-weight: 700;\n  margin-right: 1rem;\n}\n.market-header-bar-styles_HeaderBar > span:nth-of-type(2) {\n  grid-column: 3;\n}\n.market-header-bar-styles_HeaderBar > span:last-of-type {\n  flex-flow: row nowrap;\n  margin-left: auto;\n}\n.market-header-bar-styles_HeaderBar > span:last-of-type > span:last-of-type {\n  margin-top: 0;\n}\n@media all and (max-width: 767px) {\n  .market-header-bar-styles_HeaderBar > span:last-of-type {\n    flex-flow: column nowrap;\n  }\n  .market-header-bar-styles_HeaderBar > span:last-of-type > span:last-of-type {\n    margin-top: 0.1875rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"HeaderBar": "market-header-bar-styles_HeaderBar",
	"headerBar": "market-header-bar-styles_HeaderBar"
};