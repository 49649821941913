exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".create-market-view-styles_CreateMarketView {\n  display: flex;\n  justify-content: center;\n}\n.create-market-view-styles_CreateMarketView > div {\n  max-width: 750px;\n}\n.create-market-view-styles_CreateMarketView > div > div[class*=\"ContentBlock\"] {\n  width: 100%;\n}\n@media all and (max-width: 767px) {\n  .create-market-view-styles_CreateMarketView {\n    margin-top: 0;\n  }\n  .create-market-view-styles_CreateMarketView > div {\n    max-width: calc(100% - 0.625rem);\n    align-items: center;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"CreateMarketView": "create-market-view-styles_CreateMarketView",
	"createMarketView": "create-market-view-styles_CreateMarketView"
};