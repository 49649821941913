exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".link-styles_Link {\n  white-space: nowrap;\n  color: #09CFE1;\n}\n.link-styles_Link.link-styles_underline {\n  text-decoration: underline;\n}\n.link-styles_Link.link-styles_ownLine {\n  display: block;\n  margin: auto;\n}\n@media all and (max-width: 767px) {\n  .link-styles_Link.link-styles_ownLine {\n    display: inline;\n    margin: 0 0 0 0.25rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Link": "link-styles_Link",
	"link": "link-styles_Link",
	"underline": "link-styles_underline",
	"ownLine": "link-styles_ownLine"
};