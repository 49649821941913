exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".toggle-height-styles_target {\n  height: 0;\n  overflow: hidden;\n  transition: height 0.3s ease;\n}\n.toggle-height-styles_target.toggle-height-styles_open {\n  height: auto;\n}\n.toggle-height-styles_open {\n  height: auto;\n}\n.toggle-height-styles_quick {\n  transition: height 0.08s ease;\n}\n@media all and (max-width: 767px) {\n  .toggle-height-styles_target.toggle-height-styles_open-on-mobile {\n    height: auto;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"target": "toggle-height-styles_target",
	"open": "toggle-height-styles_open",
	"quick": "toggle-height-styles_quick",
	"open-on-mobile": "toggle-height-styles_open-on-mobile",
	"openOnMobile": "toggle-height-styles_open-on-mobile"
};