exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-market-row-styles_OrderMarket {\n  background: #1B1A1E;\n  border-bottom: 2px solid #0E0E0F;\n}\n.order-market-row-styles_OrderMarket:not(:last-of-type) {\n  margin-bottom: 0.125rem;\n}\n.order-market-row-styles_OrderMarket > div:first-of-type {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  font-weight: 500;\n  color: #D7DDE0;\n  margin-bottom: 0.75rem;\n  padding: 0.75rem 1rem 0;\n}\n.order-market-row-styles_OrderMarket > div:first-of-type > span {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  word-break: break-word;\n}\n.order-market-row-styles_OrderMarket > div:first-of-type > span:hover {\n  text-decoration: underline;\n}\n@media all and (max-width: 767px) {\n  .order-market-row-styles_OrderMarket {\n    margin-bottom: 0.5rem;\n    margin-top: 0;\n    border: 1px solid #0E0E0F;\n  }\n  .order-market-row-styles_OrderMarket:not(:last-of-type) {\n    margin-bottom: 0.5rem;\n  }\n  .order-market-row-styles_OrderMarket:first-of-type {\n    border-top: 0;\n  }\n  .order-market-row-styles_OrderMarket > div:first-of-type {\n    font-family: 'Inter', sans-serif;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    font-size: 0.75rem;\n    font-weight: 500;\n    padding: 0.5rem 0.75rem 0;\n    margin-bottom: 0.5rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"OrderMarket": "order-market-row-styles_OrderMarket",
	"orderMarket": "order-market-row-styles_OrderMarket"
};