exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".filter-notice-styles_filterNotice {\n  margin: 1.5rem 0;\n}\n@media all and (max-width: 767px) {\n  .filter-notice-styles_filterNotice {\n    margin: 1rem 0;\n  }\n}\n.filter-notice-styles_filterNotice > div {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  border: 1px solid #3C3B43;\n  border-radius: 0.375rem;\n  padding: 0.5rem 1rem;\n  width: 100%;\n}\n.filter-notice-styles_filterNotice > div > span:first-of-type {\n  display: flex;\n  margin: 0 1rem 0 0;\n}\n.filter-notice-styles_filterNotice > div > span:last-of-type {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  color: #9599A5;\n}\n.filter-notice-styles_filterNotice > div > span:last-of-type > a {\n  color: #09CFE1;\n}\n.filter-notice-styles_filterNotice > div > button {\n  background-color: transparent;\n  height: 0.625rem;\n  line-height: 0.625rem;\n  width: 0.625rem;\n  margin-left: auto;\n}\n.filter-notice-styles_filterNotice > div > button > svg {\n  height: 0.625rem;\n  width: 0.625rem;\n}\n.filter-notice-styles_filterNotice > div > button > svg > path {\n  stroke: #3C3B43;\n}\n.filter-notice-styles_invalid svg {\n  height: 1.1875rem;\n  width: 1.1875rem;\n  fill: #A95A63;\n}\n.filter-notice-styles_invalid rect,\n.filter-notice-styles_invalid path {\n  fill: #4E4C57;\n}\n.filter-notice-styles_primary svg {\n  height: 1.1875rem;\n  width: 1.1875rem;\n  fill: #D7DDE0;\n}\n.filter-notice-styles_primary rect,\n.filter-notice-styles_primary path {\n  fill: #252429;\n}\n", ""]);

// exports
exports.locals = {
	"filterNotice": "filter-notice-styles_filterNotice",
	"invalid": "filter-notice-styles_invalid",
	"primary": "filter-notice-styles_primary"
};