exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".overview-styles_AccountOverview {\n  display: grid;\n  grid-gap: 0.125rem;\n  margin: 0.75rem 1rem;\n}\n@media all and (max-width: 767px) {\n  .overview-styles_AccountOverview {\n    margin: 0;\n  }\n}\n.overview-styles_RepBalances {\n  align-items: center;\n  border-radius: 0.1875rem;\n  background: #161518;\n  display: grid;\n  grid-template-columns: 1fr 1.875rem 1fr;\n  grid-template-rows: 4rem;\n  grid-gap: 0.5rem;\n  padding: 0 1.75rem;\n}\n.overview-styles_RepBalances > div {\n  margin: 0 auto;\n}\n.overview-styles_RepBalances > div > span,\n.overview-styles_RepBalances > div > span:first-of-type {\n  display: block;\n  text-align: center;\n}\n.overview-styles_RepBalances > svg {\n  justify-self: center;\n  height: 1.5rem;\n  width: 1.375rem;\n}\n", ""]);

// exports
exports.locals = {
	"AccountOverview": "overview-styles_AccountOverview",
	"accountOverview": "overview-styles_AccountOverview",
	"RepBalances": "overview-styles_RepBalances",
	"repBalances": "overview-styles_RepBalances"
};