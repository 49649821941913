exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".outcome-trading-indicator-styles_Up,\n.outcome-trading-indicator-styles_Down {\n  border: solid transparent;\n  border-color: rgba(136, 183, 213, 0);\n  border-width: 0 0.4375rem 0.4375rem;\n  bottom: 45%;\n  margin-left: 1rem;\n  position: relative;\n}\n@media all and (max-width: 767px) {\n  .outcome-trading-indicator-styles_Up,\n  .outcome-trading-indicator-styles_Down {\n    border-width: 0.3125rem;\n  }\n}\n.outcome-trading-indicator-styles_Up {\n  border-bottom-color: #4E4C57;\n}\n.outcome-trading-indicator-styles_Down {\n  border-top-color: #4E4C57;\n  border-width: 0.5rem 0.4375rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"Up": "outcome-trading-indicator-styles_Up",
	"up": "outcome-trading-indicator-styles_Up",
	"Down": "outcome-trading-indicator-styles_Down",
	"down": "outcome-trading-indicator-styles_Down"
};