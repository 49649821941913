exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form-details-styles_FormDetails {\n  flex-direction: column;\n  display: flex;\n}\n.form-details-styles_FormDetails > div:not(:last-of-type) > section {\n  display: flex;\n}\n.form-details-styles_FormDetails > div:not(:last-of-type) > section > span {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.875rem;\n  margin: 0.75rem 0.75rem 0;\n  color: #D7DDE0;\n}\n.form-details-styles_FormDetails > div:not(:last-of-type) > div > div > input {\n  max-width: 13.125rem;\n}\n.form-details-styles_FormDetails > div {\n  display: flex;\n  flex-direction: column;\n}\n", ""]);

// exports
exports.locals = {
	"FormDetails": "form-details-styles_FormDetails",
	"formDetails": "form-details-styles_FormDetails"
};