exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".landing-styles_Landing {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.landing-styles_Landing > div > div:not(:last-of-type) {\n  margin-bottom: 0.75rem;\n  min-height: unset;\n}\n.landing-styles_Landing > div:last-of-type {\n  margin-bottom: 102px;\n}\n@media all and (max-width: 767px) {\n  .landing-styles_Landing {\n    align-items: flex-start;\n    padding: 0 0.5rem;\n  }\n  .landing-styles_Landing > div:last-of-type {\n    margin-bottom: 1.25rem;\n  }\n  .landing-styles_Landing > div > div:not(:last-of-type) {\n    margin-bottom: 0.5rem;\n  }\n  .landing-styles_Landing > div:nth-of-type(2) {\n    padding-bottom: 0.625rem;\n  }\n  .landing-styles_Landing > div:last-of-type > div:last-of-type > div {\n    margin-bottom: 1.125rem;\n  }\n  .landing-styles_Landing > div:last-of-type > div:last-of-type > div > p {\n    margin-top: 0.5rem;\n  }\n  .landing-styles_Landing > div:last-of-type > div:nth-of-type(2) > div {\n    margin-bottom: 0.75rem;\n  }\n  .landing-styles_Landing > div:last-of-type > div > div {\n    min-height: unset;\n    padding: unset;\n    margin-bottom: 0.25rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Landing": "landing-styles_Landing",
	"landing": "landing-styles_Landing"
};