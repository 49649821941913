exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".toasts-view-styles_ToastsView {\n  position: absolute;\n  right: 0;\n  top: 3.5rem;\n  min-width: 360px;\n  max-width: 360px;\n  z-index: 110;\n}\n@media all and (max-width: 767px) {\n  .toasts-view-styles_ToastsView {\n    position: fixed;\n    left: 0;\n    max-width: unset;\n    min-width: unset;\n  }\n}\n.toasts-view-styles_ToastsView.toasts-view-styles_MoveDown {\n  top: calc(3.5rem + 2rem);\n}\n", ""]);

// exports
exports.locals = {
	"ToastsView": "toasts-view-styles_ToastsView",
	"toastsView": "toasts-view-styles_ToastsView",
	"MoveDown": "toasts-view-styles_MoveDown",
	"moveDown": "toasts-view-styles_MoveDown"
};