exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media all and (max-width: 767px) {\n  .markets-landing-page-styles_LandingPage {\n    margin: 0 0.25rem;\n  }\n}\n.markets-landing-page-styles_LandingPage > div:nth-of-type(1) {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 1.875rem;\n  font-weight: 700;\n  color: #D7DDE0;\n  display: flex;\n  justify-content: center;\n  margin: 77px auto 24px auto;\n  text-align: center;\n  width: 411px;\n}\n@media all and (max-width: 767px) {\n  .markets-landing-page-styles_LandingPage > div:nth-of-type(1) {\n    margin: 4rem auto;\n    width: -webkit-fit-content;\n    width: fit-content;\n  }\n}\n.markets-landing-page-styles_LandingPage > div:nth-of-type(2) {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.markets-landing-page-styles_LandingPage > div:nth-of-type(3) {\n  margin-top: 87px;\n  margin-bottom: 55px;\n}\n@media all and (max-width: 450px) {\n  .markets-landing-page-styles_LandingPage > div:nth-of-type(3) > div {\n    width: 100%;\n    max-width: 100%;\n  }\n}\n.markets-landing-page-styles_LandingPage > div:nth-of-type(4) {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 1.875rem;\n  font-weight: 700;\n  color: #D7DDE0;\n  margin: 0 244px;\n}\n@media all and (max-width: 767px) {\n  .markets-landing-page-styles_LandingPage > div:nth-of-type(4) {\n    margin: 0 1rem 2rem 1rem;\n  }\n}\n.markets-landing-page-styles_LandingPage > div:nth-of-type(5) {\n  margin: 0 244px;\n}\n@media all and (max-width: 767px) {\n  .markets-landing-page-styles_LandingPage > div:nth-of-type(5) {\n    display: none;\n  }\n}\n.markets-landing-page-styles_LandingPage > article {\n  margin: 0 244px;\n}\n@media all and (max-width: 767px) {\n  .markets-landing-page-styles_LandingPage > article {\n    margin: 0 1rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"LandingPage": "markets-landing-page-styles_LandingPage",
	"landingPage": "markets-landing-page-styles_LandingPage"
};