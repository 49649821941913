exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".activity-styles_Activity {\n  align-items: center;\n  background: #161518;\n  border-radius: 0.1875rem;\n  display: flex;\n  flex-direction: column;\n  padding-top: 1rem;\n}\n.activity-styles_Activity > h4 {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 600;\n  color: #D7DDE0;\n  text-transform: uppercase;\n}\n.activity-styles_Activity > div[class*=\"LinearPropertyLabel\"] {\n  align-items: baseline;\n  min-width: 269px;\n  max-width: 80%;\n  margin: 1.5rem auto 0.5rem;\n}\n@media all and (max-width: 767px) {\n  .activity-styles_Activity > div[class*=\"LinearPropertyLabel\"] {\n    margin: 0.625rem 1.75rem 0;\n    max-width: 100%;\n  }\n}\n.activity-styles_Activity > div[class*=\"LinearPropertyLabel\"] > span:first-of-type {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.625rem;\n  font-weight: 500;\n  color: #9599A5;\n}\n.activity-styles_Activity > div[class*=\"LinearPropertyLabel\"] > span {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 500;\n  color: #D7DDE0;\n}\n", ""]);

// exports
exports.locals = {
	"Activity": "activity-styles_Activity",
	"activity": "activity-styles_Activity"
};