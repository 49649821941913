exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".funds-styles_Funds {\n  background: #161518;\n  border-radius: 0.1875rem;\n  padding: 1rem;\n  text-transform: uppercase;\n  text-align: center;\n}\n@media all and (max-width: 768px) {\n  .funds-styles_Funds {\n    padding: 1rem 1.75rem;\n  }\n}\n.funds-styles_Funds > h4 {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 700;\n  color: #9599A5;\n  margin-bottom: 0.5rem;\n}\n.funds-styles_Funds > div:first-of-type {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 1.375rem;\n  font-weight: 700;\n  margin: 0.125rem 0.25rem 1.25rem;\n}\n@media all and (max-width: 768px) {\n  .funds-styles_Funds > div:first-of-type {\n    margin-bottom: 0.75rem;\n  }\n}\n.funds-styles_Funds > div[class*=\"LinearPropertyLabel\"] {\n  align-items: baseline;\n  min-width: 269px;\n  max-width: 80%;\n  margin-left: auto;\n  margin-right: auto;\n}\n@media all and (max-width: 768px) {\n  .funds-styles_Funds > div[class*=\"LinearPropertyLabel\"] {\n    margin-left: 0;\n    margin-right: 0;\n    max-width: 100%;\n  }\n}\n.funds-styles_Funds > div[class*=\"LinearPropertyLabel\"] > span:first-of-type {\n  color: #9599A5;\n}\n.funds-styles_Funds > div[class*=\"LinearPropertyLabel\"] > span {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 500;\n  color: #D7DDE0;\n}\n", ""]);

// exports
exports.locals = {
	"Funds": "funds-styles_Funds",
	"funds": "funds-styles_Funds"
};