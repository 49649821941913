exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".null-state-message-styles_NullState {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 1.125rem;\n  color: #9599A5;\n  line-height: 1.5rem;\n  padding: 0 2rem;\n}\n.null-state-message-styles_Padding {\n  padding-bottom: 3.75rem;\n}\n@media all and (max-width: 767px) {\n  padding: 0 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"NullState": "null-state-message-styles_NullState",
	"nullState": "null-state-message-styles_NullState",
	"Padding": "null-state-message-styles_Padding",
	"padding": "null-state-message-styles_Padding"
};