exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".markets-view-styles_MarketsView {\n  max-width: 1200px;\n  margin: auto;\n  padding: 0 1rem;\n}\n@media all and (max-width: 767px) {\n  .markets-view-styles_MarketsView {\n    margin: 0 0.875rem;\n    padding: 0;\n  }\n}\n.markets-view-styles_MarketsView > div:first-of-type {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.markets-view-styles_MarketsView > div:first-of-type > div:first-of-type {\n  flex: 2;\n}\n.markets-view-styles_MarketsView > div:first-of-type > div:nth-of-type(2) {\n  margin: 0.25rem 1.5rem 0.25rem 0;\n}\n@media all and (max-width: 767px) {\n  .markets-view-styles_MarketsView > div:first-of-type {\n    align-items: baseline;\n    margin: 0;\n  }\n  .markets-view-styles_MarketsView > div:first-of-type > div:nth-of-type(2) {\n    display: none;\n  }\n  .markets-view-styles_MarketsView > div:first-of-type > div:last-of-type {\n    display: none;\n  }\n}\n.markets-view-styles_Disabled > div {\n  pointer-events: none;\n  opacity: 0.5;\n}\n@media all and (min-width: 768px) and (max-width: 1150px) {\n  .markets-view-styles_MarketsView > div:first-of-type {\n    display: grid;\n    grid-template-columns: auto 1fr;\n    margin-top: 0;\n  }\n  .markets-view-styles_MarketsView > div:first-of-type > div:first-of-type {\n    margin-top: 0.5rem;\n  }\n  .markets-view-styles_MarketsView > div:first-of-type > div:nth-of-type(2) {\n    justify-content: flex-end;\n    align-items: center;\n    margin-right: 0;\n  }\n  .markets-view-styles_MarketsView > div:first-of-type > div:nth-of-type(2) > span {\n    display: flex;\n  }\n  .markets-view-styles_MarketsView > div:first-of-type > div:last-of-type {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"MarketsView": "markets-view-styles_MarketsView",
	"marketsView": "markets-view-styles_MarketsView",
	"Disabled": "markets-view-styles_Disabled",
	"disabled": "markets-view-styles_Disabled"
};