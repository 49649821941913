exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".markets-list-styles_MarketsList > article {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin: 128px 0;\n}\n.markets-list-styles_MarketsList > article > span:first-of-type {\n  align-items: center;\n  background: #0E0E0F;\n  border-radius: 50%;\n  display: flex;\n  height: 64px;\n  justify-content: center;\n  width: 64px;\n}\n.markets-list-styles_MarketsList > article > span:first-of-type > svg path {\n  fill: #9599A5;\n}\n.markets-list-styles_MarketsList > article > span:nth-of-type(2) {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 1.125rem;\n  font-weight: 700;\n  color: #D7DDE0;\n  margin: 1rem 0 0.25rem 0;\n}\n.markets-list-styles_MarketsList > article > span:last-of-type {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.875rem;\n  color: #9599A5;\n}\n", ""]);

// exports
exports.locals = {
	"MarketsList": "markets-list-styles_MarketsList",
	"marketsList": "markets-list-styles_MarketsList"
};