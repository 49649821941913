exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sub-categories-styles_SubCategories > section:last-of-type {\n  margin-top: 1rem;\n}\n.sub-categories-styles_SubCategories > section:last-of-type > div {\n  margin-bottom: 1rem;\n}\n.sub-categories-styles_SubCategories > div:last-of-type > section:last-of-type > ul {\n  margin: 1.5rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"SubCategories": "sub-categories-styles_SubCategories",
	"subCategories": "sub-categories-styles_SubCategories"
};