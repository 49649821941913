exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".review-styles_Review {\n  display: flex;\n  flex-direction: column;\n}\n.review-styles_Review > div:first-of-type {\n  grid-template-columns: repeat(3, 1fr);\n  display: grid;\n  grid-row-gap: 2rem;\n}\n.review-styles_Review > div:first-of-type > div:first-of-type {\n  grid-column: 1 / span 10;\n}\n.review-styles_Review > div:first-of-type > div:nth-of-type(5) {\n  grid-column: 1 / span 10;\n}\n@media all and (max-width: 767px) {\n  .review-styles_Review > div:first-of-type > div:nth-of-type(3),\n  .review-styles_Review > div:first-of-type > div:last-of-type {\n    grid-column: 2 / span 3;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  .review-styles_Review > div:first-of-type > div:nth-of-type(4) {\n    grid-column: 1 / span 3;\n  }\n}\n.review-styles_Review > div:nth-of-type(3) > div:not(:last-of-type) {\n  margin-bottom: 2rem;\n}\n.review-styles_Review > div:last-of-type > span > div {\n  max-width: 260px;\n}\n.review-styles_Review > div:last-of-type > span > div > span > div {\n  max-width: 260px;\n}\n.review-styles_Review > div:last-of-type > section:last-of-type {\n  margin-top: 1rem;\n}\n.review-styles_Review.review-styles_Scalar > div:first-of-type > div:nth-of-type(8) {\n  grid-column: 3 / span 8;\n}\n.review-styles_Review.review-styles_Categorical > div:first-of-type > div:nth-of-type(6) {\n  grid-column: 1 / span 10;\n}\n@media all and (max-width: 767px) {\n  .review-styles_Review > div:first-of-type {\n    grid-row-gap: 1.375rem;\n  }\n  .review-styles_Review > div:nth-of-type(3) > div:not(:last-of-type) {\n    margin-bottom: 1.375rem;\n  }\n  .review-styles_Review > div:last-of-type > span > div {\n    max-width: unset;\n  }\n  .review-styles_Review > div:last-of-type > span > div > span > div {\n    max-width: unset;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Review": "review-styles_Review",
	"review": "review-styles_Review",
	"Scalar": "review-styles_Scalar",
	"scalar": "review-styles_Scalar",
	"Categorical": "review-styles_Categorical",
	"categorical": "review-styles_Categorical"
};