exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".reporting-styles_Reporting {\n  display: flex;\n  padding: 1rem 0.5rem;\n}\n.reporting-styles_Reporting > div:first-of-type {\n  flex: 1;\n  margin-right: 0.5rem;\n}\n.reporting-styles_Reporting > div:last-of-type > button {\n  margin-bottom: 1rem;\n}\n.reporting-styles_Reporting > div > div:last-of-type > div:first-of-type {\n  margin: 0;\n}\n.reporting-styles_Reporting > div > div:last-of-type > button {\n  margin-top: 1rem;\n}\n@media all and (min-width: 1680px) {\n  .reporting-styles_Reporting {\n    max-width: 1657px;\n    display: grid;\n    grid-template-columns: minmax(767px, 1fr) minmax(340px, 440px);\n    margin: auto;\n  }\n}\n@media all and (min-width: 1151px) {\n  .reporting-styles_Reporting > div:last-of-type {\n    max-width: 440px;\n    width: 100%;\n  }\n  .reporting-styles_Reporting > div:last-of-type > div:first-of-type {\n    margin-bottom: 1rem;\n  }\n}\n@media all and (min-width: 768px) and (max-width: 1150px) {\n  .reporting-styles_Reporting > div:first-of-type {\n    flex: 1;\n    margin-right: 0.5rem;\n  }\n  .reporting-styles_Reporting > div:last-of-type {\n    flex: 1;\n  }\n  .reporting-styles_Reporting > div:last-of-type > div:first-of-type {\n    margin-bottom: 1rem;\n  }\n}\n@media all and (max-width: 767px) {\n  .reporting-styles_Reporting {\n    padding: 0;\n  }\n  .reporting-styles_Reporting > div:first-of-type {\n    margin-right: 0;\n  }\n  .reporting-styles_Reporting > div > div:last-of-type {\n    padding: 0 0.625rem;\n  }\n  .reporting-styles_Reporting > div > div:last-of-type > button {\n    margin-bottom: 1rem;\n  }\n  .reporting-styles_Reporting > div > div:last-of-type > div:first-of-type {\n    max-width: unset;\n    margin-bottom: 1rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Reporting": "reporting-styles_Reporting",
	"reporting": "reporting-styles_Reporting"
};