exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".trading-form-styles_TradingForm {\n  background-color: #1B1A1E;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  min-height: 575px;\n  position: relative;\n}\n@media all and (max-width: 767px) {\n  .trading-form-styles_TradingForm {\n    flex-grow: 1;\n    min-height: auto;\n    -webkit-overflow-scrolling: touch;\n    overflow-y: auto;\n    border-bottom: 1px solid #0E0E0F;\n    border-left: 1px solid #0E0E0F;\n    border-right: 1px solid #0E0E0F;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"TradingForm": "trading-form-styles_TradingForm",
	"tradingForm": "trading-form-styles_TradingForm"
};