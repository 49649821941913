exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-header-styles_OrderHeader {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  z-index: 2;\n}\n.order-header-styles_OrderHeader > span {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.75rem;\n  font-weight: 700;\n  background: #0E0E0F;\n  color: #D7DDE0;\n  padding: 0.5rem 0.625rem 0.5rem 1rem;\n  display: flex;\n  justify-content: space-between;\n  text-transform: uppercase;\n  height: 2rem;\n  max-height: 2rem;\n  min-height: 2rem;\n}\n.order-header-styles_OrderHeader > span.order-header-styles_WithStatus {\n  padding: 0.5rem 0.625rem 0.5rem 0.5rem;\n}\n@media all and (max-width: 767px) {\n  .order-header-styles_OrderHeader > span {\n    display: none;\n  }\n}\n.order-header-styles_OrderHeader > ul {\n  align-items: center;\n  background-color: #161518;\n  border-bottom: 1px solid #0E0E0F;\n  height: 2rem;\n  max-height: 2rem;\n  min-height: 2rem;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  grid-template-rows: auto;\n  grid-gap: 0 1rem;\n}\n.order-header-styles_OrderHeader > ul > li {\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-family: 'Inter', sans-serif;\n  font-feature-settings: \"tnum\";\n  font-size: 0.625rem;\n  font-weight: 500;\n  color: #9599A5;\n  text-transform: capitalize;\n  white-space: nowrap;\n  text-align: right;\n}\n.order-header-styles_OrderHeader > ul > li:first-of-type {\n  margin-left: 2.5rem;\n  text-align: left;\n}\n.order-header-styles_OrderHeader > ul > li:nth-of-type(2) {\n  text-align: center;\n}\n.order-header-styles_OrderHeader > ul > li:last-of-type {\n  padding-right: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"OrderHeader": "order-header-styles_OrderHeader",
	"orderHeader": "order-header-styles_OrderHeader",
	"WithStatus": "order-header-styles_WithStatus",
	"withStatus": "order-header-styles_WithStatus"
};