exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".main-error-boundary-styles_MainErrorBoundary {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n}\n.main-error-boundary-styles_MainErrorBoundary > div {\n  background: #1B1A1E;\n  border: 1px solid #0E0E0F;\n  border-radius: 0.1875rem;\n  color: #D7DDE0;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  max-width: 35rem;\n  padding: 1.5rem 0;\n  text-align: center;\n  width: 100%;\n}\n.main-error-boundary-styles_MainErrorBoundary > div > h1 {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 1.5rem;\n  font-weight: 700;\n  margin: 0.5rem 0 1.5rem;\n  padding: 0;\n}\n.main-error-boundary-styles_MainErrorBoundary > div > p {\n  margin: 0 0 1.5rem;\n  padding: 0 1.5rem;\n}\n.main-error-boundary-styles_MainErrorBoundary > div > div {\n  align-items: center;\n  border-top: 1px solid #0E0E0F;\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  padding: 1.5rem 0 0;\n}\n.main-error-boundary-styles_MainErrorBoundary > div > div > a,\n.main-error-boundary-styles_MainErrorBoundary > div > div > button {\n  margin: auto;\n}\n", ""]);

// exports
exports.locals = {
	"MainErrorBoundary": "main-error-boundary-styles_MainErrorBoundary",
	"mainErrorBoundary": "main-error-boundary-styles_MainErrorBoundary"
};