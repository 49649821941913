exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rate-styles_Rate {\n  align-items: center;\n  background: #161518;\n  border-bottom-left-radius: 0.75rem;\n  border-bottom-right-radius: 0.75rem;\n  display: flex;\n  justify-content: space-between;\n  margin: 0 0.5rem;\n  padding: 0.5rem;\n}\n.rate-styles_Rate > div {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  color: #9599A5;\n}\n.rate-styles_RatePool {\n  background: #161518;\n  border-radius: 0 0 0.3125rem 0.3125rem;\n  margin: 0 0.5rem;\n  padding: 1rem;\n}\n.rate-styles_RatePool > div {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  color: #9599A5;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"Rate": "rate-styles_Rate",
	"rate": "rate-styles_Rate",
	"RatePool": "rate-styles_RatePool",
	"ratePool": "rate-styles_RatePool"
};