exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media all and (max-width: 767px) {\n  .favorites-styles_Watchlist > div:last-of-type > div:nth-of-type(2):not([class*=\"EmptyDisplay\"]) {\n    border-top: 1px solid #0E0E0F;\n  }\n  .favorites-styles_Watchlist > div:last-of-type > div[class*=\"MarketRow\"]:last-of-type {\n    border-radius: 0;\n  }\n  .favorites-styles_Watchlist > div:last-of-type > div[class*=\"MarketRow\"] > div {\n    border-left: 1px solid #0E0E0F;\n    border-right: 1px solid #0E0E0F;\n  }\n  .favorites-styles_Watchlist > div:last-of-type div[class*=\"box-header-styles_ShowOnMobile\"] > div:first-of-type {\n    height: 2rem;\n    min-height: 2rem;\n    max-height: 2rem;\n    margin-top: 0.5rem;\n    margin-bottom: 1rem;\n  }\n  .favorites-styles_Watchlist > div:last-of-type div[class*=\"box-header-styles_ShowOnMobile\"] > div[class*=\"Middle\"] > div[class*=\"BottomContent\"] {\n    margin-top: 0.5rem;\n    border-radius: 0.1875rem;\n  }\n  .favorites-styles_Watchlist > div:last-of-type div[class*=\"box-header-styles_ShowOnMobile\"] > div[class*=\"Middle\"] > div[class*=\"BottomContent\"] > div {\n    align-items: center;\n    padding: 0;\n    min-height: 2.5rem;\n    height: 2.5rem;\n    max-height: 2.5rem;\n    justify-content: space-around;\n  }\n  .favorites-styles_Watchlist > div:last-of-type div[class*=\"box-header-styles_ShowOnMobile\"] > div[class*=\"Middle\"] > div[class*=\"BottomContent\"] > div > button {\n    margin: 0;\n    padding: 0;\n    min-height: 2.5rem;\n    height: 2.5rem;\n    max-height: 2.5rem;\n  }\n  .favorites-styles_Watchlist > div:last-of-type div[class*=\"box-header-styles_ShowOnMobile\"] > div[class*=\"Middle\"] > div[class*=\"BottomContent\"] > div > button > span {\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    font-family: 'Inter', sans-serif;\n    font-feature-settings: \"tnum\";\n    font-size: 0.625rem;\n    font-weight: 500;\n    text-transform: capitalize;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"Watchlist": "favorites-styles_Watchlist",
	"watchlist": "favorites-styles_Watchlist"
};