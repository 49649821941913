exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".chevron-flip-styles_ChevronFlip {\n  cursor: pointer;\n  transition: transform 0.3s ease;\n  width: 0.625rem;\n}\n.chevron-flip-styles_ChevronFlip.chevron-flip-styles_pointDown {\n  transform: rotate(-180deg);\n}\n.chevron-flip-styles_ChevronFlip > polyline {\n  fill: #9599A5;\n}\n.chevron-flip-styles_ChevronFlip.chevron-flip-styles_big {\n  width: 1.5rem;\n}\n.chevron-flip-styles_ChevronFlip.chevron-flip-styles_quick {\n  transition: transform 0.2s ease-in-out;\n}\n.chevron-flip-styles_ChevronFlip.chevron-flip-styles_instant {\n  transition: none;\n}\n.chevron-flip-styles_ChevronFlip.chevron-flip-styles_hover:hover > g > g {\n  stroke: #D7DDE0;\n}\n.chevron-flip-styles_ChevronFlip.chevron-flip-styles_hover:hover > path {\n  stroke: #D7DDE0;\n}\n", ""]);

// exports
exports.locals = {
	"ChevronFlip": "chevron-flip-styles_ChevronFlip",
	"chevronFlip": "chevron-flip-styles_ChevronFlip",
	"pointDown": "chevron-flip-styles_pointDown",
	"big": "chevron-flip-styles_big",
	"quick": "chevron-flip-styles_quick",
	"instant": "chevron-flip-styles_instant",
	"hover": "chevron-flip-styles_hover"
};