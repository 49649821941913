exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form-styles_Form {\n  color: #D7DDE0;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  padding-bottom: 87px;\n  padding-top: 2rem;\n}\n.form-styles_Form > div:last-of-type > div:last-of-type {\n  margin-top: 2.5rem;\n  display: flex;\n}\n@media all and (max-width: 767px) {\n  .form-styles_Form > div:last-of-type > div:last-of-type {\n    margin-top: 2.25rem;\n  }\n}\n.form-styles_Form > div:last-of-type > div:last-of-type > div {\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n}\n.form-styles_Form > div:last-of-type > div:last-of-type > div > button:nth-last-of-type(2) {\n  margin-right: 1.5rem;\n}\n.form-styles_Form > button {\n  margin-bottom: 1.5rem;\n}\n.form-styles_Form.form-styles_Preview {\n  flex: 1;\n  max-width: unset;\n  bottom: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: 110;\n  padding: 0;\n}\n.form-styles_Form.form-styles_Preview > div {\n  background: #161518;\n  display: grid;\n  width: 100%;\n  align-items: center;\n  justify-items: center;\n  overflow-y: auto;\n}\n.form-styles_Form.form-styles_Preview > div > span {\n  width: 100%;\n  margin-top: 0;\n}\n.form-styles_Form.form-styles_Preview > div > button {\n  margin: 0.875rem 0;\n}\n.form-styles_Form.form-styles_Preview > div > button:first-of-type {\n  margin: 1rem 0;\n}\n@media all and (max-width: 767px) {\n  .form-styles_Form {\n    padding: 1rem 0.5rem 46px;\n    align-items: flex-start;\n  }\n  .form-styles_Form > span {\n    padding-left: 0.5rem;\n  }\n}\n.form-styles_MultipleTransactions {\n  margin: 1.5rem 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"Form": "form-styles_Form",
	"form": "form-styles_Form",
	"Preview": "form-styles_Preview",
	"preview": "form-styles_Preview",
	"MultipleTransactions": "form-styles_MultipleTransactions",
	"multipleTransactions": "form-styles_MultipleTransactions"
};