exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".markets-in-dispute-styles_OnlyPortfolio {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.75rem;\n  align-items: center;\n  margin-left: auto;\n  display: flex;\n  flex-flow: row nowrap;\n}\n@media all and (max-width: 767px) {\n  .markets-in-dispute-styles_OnlyPortfolio {\n    margin-top: 0.5rem;\n    margin-left: 0;\n  }\n}\n.markets-in-dispute-styles_OnlyPortfolio > div {\n  margin-right: 2rem;\n}\n.markets-in-dispute-styles_MarketsInDispute > label {\n  font-family: 'Inter', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  font-size: 0.625rem;\n  font-weight: 500;\n  display: grid;\n  grid-template-columns: 1.5rem auto;\n  margin: 0.75rem 0.5rem;\n  align-items: center;\n}\n.markets-in-dispute-styles_MarketsInDispute > label > div {\n  margin-bottom: 0;\n}\n.markets-in-dispute-styles_MarketCards {\n  display: grid;\n  grid-gap: 0.75rem;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto;\n  min-height: 300px;\n  padding: 0.75rem;\n}\n.markets-in-dispute-styles_MarketCards > div[class*=\"MarketCard\"],\n.markets-in-dispute-styles_MarketCards > div[class*=\"EmptyDisplay\"] {\n  margin: 0;\n}\n@media all and (max-width: 768px) {\n  .markets-in-dispute-styles_MarketCards {\n    padding: 0.75rem 0 0 0;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"OnlyPortfolio": "markets-in-dispute-styles_OnlyPortfolio",
	"onlyPortfolio": "markets-in-dispute-styles_OnlyPortfolio",
	"MarketsInDispute": "markets-in-dispute-styles_MarketsInDispute",
	"marketsInDispute": "markets-in-dispute-styles_MarketsInDispute",
	"MarketCards": "markets-in-dispute-styles_MarketCards",
	"marketCards": "markets-in-dispute-styles_MarketCards"
};